import React, { useEffect, useState } from 'react';
import PhoneInput from 'react-phone-input-2';

import 'react-phone-input-2/lib/style.css';
import InputLabel from '../InputLabel';
import PropTypes from 'prop-types';
import './index.scss';
import InputAddSubIcons from '../InputAddIcons';
import { isDuplicate } from '../../../utils/generic/helper';
const InputPhoneWithCountryCode = ({
  label,
  value,
  isMandatory,
  onChange,
  isError,
  errorMessage,
  placeholder,
  disabled,
  showIcons,
  addDelEntryRow,
  getAllMultiInputData,
  validateData,
  showInputErrors,
  multInputValues,
  resetInputs,
  updateResetInputs,
  name,
  duplicateValidation,
}) => {
  const [textInputs, setTextInputs] = useState([]);
  const addTextInput = () => {
    setTextInputs([...textInputs, { name: `${name}${textInputs.length + 1}`, value: '' }]);
  };
  const onInputValueChange = (val, id) => {
    const textInputsCopy = JSON.parse(JSON.stringify(textInputs));
    textInputsCopy.forEach((item, index) => {
      if (item.name == id) {
        item.value = val;
      }
    });
    setTextInputs(textInputsCopy);
    getAllMultiInputData(textInputsCopy);
  };

  const callToAddInputs = () => {
    if (duplicateValidation) {
      if (!isDuplicate([...textInputs.map(val => val.value), value]) && textInputs?.length < 9)
        addTextInput();
    } else {
      if (textInputs?.length < 9) {
        addTextInput();
      }
    }
    addDelEntryRow(textInputs?.length, [...textInputs.map(val => val.value), value]);
  };

  const removeTextInput = name => {
    let textInputsCopy = JSON.parse(JSON.stringify(textInputs));
    textInputsCopy = textInputsCopy.filter(el => el.name != name);
    setTextInputs(textInputsCopy);
    getAllMultiInputData(textInputsCopy);
  };

  const showErrors = element => {
    if (element?.value) {
      const error = validateData(element.value);
      if (showInputErrors && error) {
        return (
          // <div className="childItem3">
          <p className="error-text">{error}</p>
          // </div>
        );
      }
    }
  };

  useEffect(() => {
    if (resetInputs) {
      textInputs.length > 0 && setTextInputs([]);
      updateResetInputs();
    }
  }, [resetInputs]);

  useEffect(() => {
    if (multInputValues.length > 0) {
      const multiInputValueArr = multInputValues.map((x, i) => {
        return { name: `${name}${i + 1}`, value: x };
      });
      setTextInputs(multiInputValueArr);
      getAllMultiInputData(multiInputValueArr);
    } else {
      setTextInputs([]);
      getAllMultiInputData([]);
    }
  }, [multInputValues]);

  return (
    <div className="inputPhoneWithCountryCode">
      <InputLabel labelFor="inputPhoneWithCode" text={label} isMandatory={isMandatory} />
      <div className={showIcons && 'phoneInputWithIcon mb-15'}>
        <PhoneInput
          country={'af'}
          value={value}
          onBlur={event => onChange(event.target.value)}
          onChange={(value, data, event, formattedValue) => {
            onChange(formattedValue);
          }}
          placeholder={placeholder}
          disabled={disabled}
          copyNumbersOnly={false}
          enableLongNumbers={true}
        />
        {showIcons && (
          <InputAddSubIcons
            showInputs={true}
            showMinus={false}
            addTextInput={addTextInput}
            callToAddInputs={callToAddInputs}
            disabled={disabled}
          />
        )}
      </div>
      {isError && <p className="error-text">{errorMessage}</p>}
      {textInputs.map((element, index) => {
        return (
          <>
            <div key={index} className={showIcons && 'inputWithIconPhone mb-15'}>
              <PhoneInput
                country={'af'}
                onBlur={event => {
                  onInputValueChange(event.target.value, element.name);
                }}
                placeholder={placeholder}
                disabled={disabled}
                copyNumbersOnly={false}
                enableLongNumbers={true}
                key={element.id}
                name={element.name}
                value={element.value}
                onChange={(value, data, event, formattedValue) => {
                  onInputValueChange(formattedValue, element.name);
                }}
              />
              {showIcons && (
                <InputAddSubIcons
                  index={index + 1}
                  showInputs={true}
                  addTextInput={addTextInput}
                  callToAddInputs={callToAddInputs}
                  removeTextInput={removeTextInput}
                  name={element.name}
                  className="childItem2"
                  disabled={disabled}
                />
              )}
            </div>
            {showIcons && showErrors(element)}
          </>
        );
      })}
    </div>
  );
};

InputPhoneWithCountryCode.propTypes = {
  value: PropTypes.string.isRequired,
  label: PropTypes.string,
  isMandatory: PropTypes.bool,
  isError: PropTypes.bool,
  errorMessage: PropTypes.string,
  isClearable: PropTypes.bool,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  showIcons: PropTypes.bool,
  addDelEntryRow: PropTypes.func,
  validateData: PropTypes.func,
  multInputValues: PropTypes.array,
  getAllMultiInputData: PropTypes.func,
  resetInputs: PropTypes.bool,
  updateResetInputs: PropTypes.func,
  duplicateValidation: PropTypes.bool,
};

InputPhoneWithCountryCode.defaultProps = {
  label: '',
  isMandatory: false,
  isError: false,
  errorMessage: '',
  isClearable: false,
  onChange: () => {},
  disabled: false,
  showIcons: false,
  addDelEntryRow: () => {},
  validateData: () => {},
  multInputValues: [],
  getAllMultiInputData: () => {},
  resetInputs: false,
  updateResetInputs: () => {},
  duplicateValidation: false,
};

export default InputPhoneWithCountryCode;
