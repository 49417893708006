/* eslint-disable generator-star-spacing */
import { takeEvery, put, select, all, call } from 'redux-saga/effects';
import * as requirementAction from '../actions/requirement';
import * as globalActions from '../actions/globalActions';
import { requirement as requirementApi } from '../../api';
import { ALERT_MESSAGES, REQUIREMENT_TYPES, MODAL_TYPES } from '../../utils/generic/constants';
import { resetTab } from '../../screens/requirement/features/resultsDisclosureInRegistry/sets';
import { getLockMessageInfo, getApiErrorMessage } from '../../utils/generic/helper';
import { getSearchResults } from '../actions/advancedSearch';

export function* deleteRequirementAsync({ payload: { requirementId, postParam } }) {
  try {
    yield put(globalActions.showSpinner());
    const response = yield requirementApi.deleteRequirement(requirementId);
    yield put(globalActions.hideSpinner());
    const {
      data: { state, messages },
    } = response;

    if (state) {
      yield put(globalActions.showAlert(ALERT_MESSAGES.REQUIREMENT_DELETE));
      window.location.reload();
    } else {
      const modalData = {
        isOpen: true,
        message:
          messages && messages.length
            ? messages[0].errorMessage
            : ALERT_MESSAGES.REQUIREMENT_ERROR.message,
        modalType: MODAL_TYPES.ERROR,
      };
      yield put(globalActions.showModal(modalData));
      yield put(globalActions.hideSpinner());
    }
  } catch (err) {
    yield put(globalActions.hideSpinner());
    yield put(
      globalActions.showModal({
        isOpen: true,
        message: err.message,
        modalType: MODAL_TYPES.ERROR,
      }),
    );
  }
}

export function* saveRequirementAsync({ payload }) {
  const { isResetSet1 = false, resetTabId, ...payloadData } = payload;
  try {
    yield put(globalActions.showSpinner());
    const { original = {} } = yield select(state => state.requirement);
    const { id = 0, requirementName = null, productionId } = original;
    const forceUpdate = [REQUIREMENT_TYPES[Object.keys(payload)[0]]];
    const response = yield requirementApi.saveRequirementDetails({
      id,
      forceUpdate,
      requirementName,
      productionId,
      ...payloadData,
    });
    const { state, data, messages } = response.data;
    if (state) {
      yield call(setTabNames, payload);
      if (payload.forceUpdate?.toString() === '5,6,7') {
        let updatedData;

        if (payload?.resultDisclosureModel?.tab === 2) {
          yield put(requirementAction.saveRequirementDetails(resetTab(3, true)));
          updatedData = {
            ...data,
            resultDisclosureModel: {
              ...data.resultDisclosureModel,
              resultDisclosure: data.resultDisclosureModel.resultDisclosure.map(data => {
                if (data?.tabId === 1) {
                  return { ...data, isThereOtherStudyTypeOrPhase: false };
                }
                return data;
              }),
            },
          };
        }
        if (payload?.resultDisclosureModel?.tab === 3) {
          updatedData = {
            ...data,
            resultDisclosureModel: {
              ...data.resultDisclosureModel,
              resultDisclosure: data.resultDisclosureModel.resultDisclosure.map(data => {
                if ([2, isResetSet1 ? 1 : undefined].includes(data?.tabId)) {
                  return { ...data, isThereOtherStudyTypeOrPhase: false };
                }
                return data;
              }),
            },
          };
        }

        yield put(requirementAction.saveRequirementDetailsSuccess(updatedData));
      } else {
        yield put(requirementAction.saveRequirementDetailsSuccess(data));
        yield put(globalActions.showAlert(ALERT_MESSAGES.REQUIREMENT_SAVE));
      }
    } else {
      const modalData = {
        isOpen: true,
        message:
          messages && messages.length
            ? messages[0].errorMessage
            : ALERT_MESSAGES.REQUIREMENT_ERROR.message,
        modalType: MODAL_TYPES.ERROR,
      };
      yield put(globalActions.showModal(modalData));
    }
    yield put(globalActions.hideSpinner());
    if (resetTabId) {
      yield put(requirementAction.saveRequirementDetails(resetTab(resetTabId)));
    }
  } catch (err) {
    yield put(globalActions.hideSpinner());
    yield put(requirementAction.setError());
    yield put(globalActions.showAlert(ALERT_MESSAGES.REQUIREMENT_ERROR));
  }
}

export function* getRequirementMasterDataAsync() {
  try {
    yield put(globalActions.showSpinner());

    const response = yield requirementApi.getRequirementMasterData();

    const { state, data } = response.data;
    if (state) {
      yield put(requirementAction.getRequirementMasterDataSuccess(data));
    }
    yield put(globalActions.hideSpinner());
  } catch (err) {
    yield put(globalActions.hideSpinner());
    yield put(requirementAction.setError());
  }
}

export function* setTabNames(data) {
  const clinical = data?.clinicalTrialCharacteristicsModel?.clinicalTrialCharactersDetailsModel;
  try {
    if (clinical?.length) {
      for (let i = 0; i < clinical?.length; i++) {
        yield put(
          requirementAction.setTabNames({
            tabId: clinical[i].tabId,
            abbreviatedName: clinical[i]?.actAbbrevName?.value || '',
          }),
        );
      }
    }
  } catch (err) {
    console.log({ err });
  }
}

export function* getRequirementAsync({ payload }) {
  try {
    yield put(globalActions.showSpinner());
    const { id, viewPagePayload } = payload;
    const reqDetails = yield requirementApi.getRequirementDetails(id);
    if (reqDetails) {
      const { state, data, messages } = reqDetails.data;
      if (state) {
        yield call(setTabNames, data);
        yield put(requirementAction.saveRequirementDetailsSuccess(data));
        if (viewPagePayload)
          yield put(requirementAction.updateRequirementModuleSelection(viewPagePayload));
      }
      if (data?.productionStatus !== 2) {
        const lockDetails = yield requirementApi.getUserLockDetails(id);
        if (lockDetails) {
          const {
            data: { state, data, messages },
          } = lockDetails;
          if (state) {
            yield put(requirementAction.getRequirementUserLockDetails(data));
            const { isEditable, lockedBy } = data;
            if (!isEditable) {
              yield put(
                globalActions.showModal({
                  isOpen: true,
                  message: getLockMessageInfo(lockedBy),
                  modalType: MODAL_TYPES.INFO,
                }),
              );
            }
          } else {
            yield put(
              globalActions.showModal({
                isOpen: true,
                message: getApiErrorMessage(messages),
                modalType: MODAL_TYPES.ERROR,
              }),
            );
          }
        }
      }
    }

    yield put(globalActions.hideSpinner());
  } catch (err) {
    yield put(globalActions.hideSpinner());
    yield put(requirementAction.setError());
  }
}

export function* moveOutofScopeRequirementAsync() {
  try {
    yield put(globalActions.showSpinner());
    const { original = {} } = yield select(state => state.requirement);
    const { id = 0, requirementName = null } = original;
    const forceUpdate = [10];
    const productionStatus = 3;
    const response = yield requirementApi.saveRequirementDetails({
      id,
      forceUpdate,
      requirementName,
      productionStatus,
    });
    const { state, data, messages } = response.data;
    console.log(state);
    const delay = ms => new Promise(res => setTimeout(res, ms));
    if (state) {
      put(requirementAction.saveRequirementDetailsSuccess(data));
      yield put(globalActions.showAlert(ALERT_MESSAGES.REQUIREMENT_OUTOFSCOPE));
      yield delay(2000);
      window.location.reload();
    } else {
      const modalData = {
        isOpen: true,
        message:
          messages && messages.length
            ? messages[0].errorMessage
            : ALERT_MESSAGES.REQUIREMENT_ERROR.message,
        modalType: MODAL_TYPES.ERROR,
      };
      yield put(globalActions.showModal(modalData));
    }
    yield put(globalActions.hideSpinner());
  } catch (err) {
    yield put(globalActions.hideSpinner());
    yield put(requirementAction.setError());
    yield put(globalActions.showAlert(ALERT_MESSAGES.REQUIREMENT_ERROR));
  }
}

export function* getStagedIdRequirementAsync({ payload }) {
  try {
    yield put(globalActions.showSpinner());
    const id = payload;
    const response = yield requirementApi.getReqStageRecordId(id);
    const { state, data, messages } = response.data;
    if (state) {
      yield put(requirementAction.getStagedIdRequirementSuccess(data));
    } else {
      const modalData = {
        isOpen: true,
        message:
          messages && messages.length
            ? messages[0].errorMessage
            : ALERT_MESSAGES.REQUIREMENT_ERROR.message,
        modalType: MODAL_TYPES.ERROR,
      };
      yield put(globalActions.showModal(modalData));
      yield put(globalActions.hideSpinner());
    }
  } catch (err) {
    yield put(globalActions.hideSpinner());
    yield put(requirementAction.setError());
    yield put(globalActions.showAlert(ALERT_MESSAGES.REQUIREMENT_ERROR));
  }
}

export function* validateRequirementAsync() {
  try {
    yield put(globalActions.showSpinner());
    const { original = {} } = yield select(state => state.requirement);
    const { id = 0, requirementName = null } = original;
    const forceUpdate = [10];
    const productionStatus = 1;
    const response = yield requirementApi.saveRequirementDetails({
      id,
      forceUpdate,
      requirementName,
      productionStatus,
    });
    const { state, data, messages } = response.data;
    if (state) {
      yield put(requirementAction.saveRequirementDetailsSuccess(data));
      yield put(globalActions.showAlert(ALERT_MESSAGES.REQUIREMENT_VALIDATE));
    } else {
      const modalData = {
        isOpen: true,
        message:
          messages && messages.length
            ? messages[0].errorMessage
            : ALERT_MESSAGES.COUNTRY_ERROR.message,
        modalType: MODAL_TYPES.ERROR,
      };
      yield put(globalActions.showModal(modalData));
    }
    yield put(globalActions.hideSpinner());
  } catch (err) {
    yield put(globalActions.hideSpinner());
    yield put(requirementAction.setError());
    yield put(globalActions.showAlert(ALERT_MESSAGES.REQUIREMENT_ERROR));
  }
}

export function* publishRequirementAsync({ payload }) {
  const { id, auditProperties } = payload;
  try {
    yield put(globalActions.showSpinner());
    const { original = {} } = yield select(state => state.requirement);
    const { requirementName = null } = original;
    const forceUpdate = [10];
    const productionStatus = 2;
    const response = yield requirementApi.saveRequirementDetails({
      id,
      forceUpdate,
      requirementName,
      productionStatus,
      auditProperties,
    });
    const { state, data, messages } = response.data;
    // const delay = ms => new Promise(res => setTimeout(res, ms));
    if (state) {
      yield put(requirementAction.saveRequirementDetailsSuccess(data));
      yield put(globalActions.showAlert(ALERT_MESSAGES.REQUIREMENT_PUBLISHED));
      const payload = {
        selectedEntity: 'dashboard',
        searchData: {
          entityId: 0,
          sortOrder: { columnName: 'Country', isDescendingOrder: 'true' },
        },
      };
      if (auditProperties) {
        window.location.href = '/dashboard';
      } else {
        yield put(getSearchResults(payload));
      }

      // yield delay(1000);
      // window.location.reload();
    } else {
      const modalData = {
        isOpen: true,
        message:
          messages && messages.length
            ? messages[0].errorMessage
            : ALERT_MESSAGES.REQUIREMENT_ERROR.message,
        modalType: MODAL_TYPES.ERROR,
      };
      yield put(globalActions.showModal(modalData));
    }
    yield put(globalActions.hideSpinner());
  } catch (err) {
    yield put(globalActions.hideSpinner());
    yield put(requirementAction.setError());
    yield put(globalActions.showAlert(ALERT_MESSAGES.REQUIREMENT_ERROR));
  }
}

export function* getRequirementRecordChangeFldsListAsync({ payload }) {
  try {
    yield put(globalActions.showSpinner());
    const { id } = payload;
    const response = yield requirementApi.getReqRecordChangeFieldsList(id);

    //const { state, data } = response.data;
    if (response.status) {
      yield put(requirementAction.requirementRecordChangeFldsListSuccess(response.data));
    }
    yield put(globalActions.hideSpinner());
  } catch (err) {
    yield put(globalActions.hideSpinner());
    yield put(requirementAction.setError());
  }
}

export function* getRequirementStampingDetailsAsync({ payload: { body } }) {
  try {
    yield put(globalActions.showSpinner());
    const response = yield requirementApi.saveRequirementDetails(body);
    const { state, data } = response.data;
    if (state) {
      yield put(requirementAction.getRequirementStampingDetailsSuccess({ data }));
    } else {
      yield put(globalActions.showAlert(ALERT_MESSAGES.TRIALS_ERROR));
    }
    yield put(globalActions.hideSpinner());
  } catch (err) {
    yield put(globalActions.hideSpinner());
    yield put(requirementAction.setError());
  }
}

export function* releaseRequirementUserLockAsync({ payload }) {
  try {
    yield requirementApi.unlockUserRecords(payload);
  } catch (err) {
    yield put(globalActions.showAlert(ALERT_MESSAGES.TRIALS_ERROR));
  }
}

export function* updateRequirementLockDetailsAsync({ payload }) {
  try {
    yield requirementApi.getUserLockDetails(payload);
  } catch (err) {
    yield put(globalActions.showAlert(ALERT_MESSAGES.TRIALS_ERROR));
  }
}

export function* getRequirementCompareDataAsync({ payload: { selectedIds, isIngestedId } }) {
  try {
    yield put(globalActions.showSpinner());
    const selectedQueueAPI = requirementApi.getRequirementDetails;
    const [idOneDetails, idTwoDetails, idThreeDetails, idFourDetails] = yield all([
      selectedQueueAPI(selectedIds[0]),
      selectedQueueAPI(selectedIds[1]),
      selectedIds[2] ? selectedQueueAPI(selectedIds[2]) : '',
      selectedIds[3] ? selectedQueueAPI(selectedIds[3]) : '',
    ]);
    if (idOneDetails && idTwoDetails && idThreeDetails && idFourDetails) {
      const { data: idOneData, state: idOneState } = idOneDetails.data;
      const { data: idTwoData, state: idTwoState } = idTwoDetails.data;
      const { data: idThreeData, state: idThreeState } = idThreeDetails.data;
      const { data: idFourData, state: idFourState } = idFourDetails.data;
      if (idOneState && idTwoState && idThreeState && idFourState) {
        yield put(
          requirementAction.getRequirementCompareDataSuccess({
            compareIdOne: idOneData,
            compareIdTwo: idTwoData,
            compareIdThree: idThreeData,
            compareIdFour: idFourData,
          }),
        );
      }
    } else if (idOneDetails && idTwoDetails && idThreeDetails) {
      const { data: idOneData, state: idOneState } = idOneDetails.data;
      const { data: idTwoData, state: idTwoState } = idTwoDetails.data;
      const { data: idThreeData, state: idThreeState } = idThreeDetails.data;
      if (idOneState && idTwoState && idThreeState) {
        yield put(
          requirementAction.getRequirementCompareDataSuccess({
            compareIdOne: idOneData,
            compareIdTwo: idTwoData,
            compareIdThree: idThreeData,
          }),
        );
      }
    } else if (idOneDetails && idTwoDetails) {
      const { data: idOneData, state: idOneState } = idOneDetails.data;
      const { data: idTwoData, state: idTwoState } = idTwoDetails.data;
      if (idOneState && idTwoState) {
        yield put(
          requirementAction.getRequirementCompareDataSuccess({
            compareIdOne: idOneData,
            compareIdTwo: idTwoData,
          }),
        );
      }
    } else {
      yield put(
        globalActions.showModal({
          isOpen: true,
          message: 'Something went wrong',
          modalType: MODAL_TYPES.ERROR,
        }),
      );
    }
    yield put(globalActions.hideSpinner());
  } catch (err) {
    yield put(
      globalActions.showModal({
        isOpen: true,
        message: err.message,
        modalType: MODAL_TYPES.ERROR,
      }),
    );
    yield put(globalActions.hideSpinner());
  }
}

export const watchers = [
  function* watchDeleteRequirementAsyncAsync() {
    yield takeEvery(requirementAction.deleteRequirement, deleteRequirementAsync);
  },
  function* watchSaveRequirement() {
    yield takeEvery(requirementAction.saveRequirementDetails, saveRequirementAsync);
  },
  function* watchGetRequirementMasterData() {
    yield takeEvery(requirementAction.getRequirementMasterData, getRequirementMasterDataAsync);
  },
  function* watchGetRequirementList() {
    yield takeEvery(requirementAction.getRequirementDetails, getRequirementAsync);
  },
  function* watchOutofScopeRequirement() {
    yield takeEvery(requirementAction.moveOutofScopeRequirement, moveOutofScopeRequirementAsync);
  },
  function* watchGetReqStageRecordId() {
    yield takeEvery(requirementAction.getStagedIdRequirement, getStagedIdRequirementAsync);
  },
  function* watchPublishRequirement() {
    yield takeEvery(requirementAction.publishRequirement, publishRequirementAsync);
  },
  function* watchValidateRequirement() {
    yield takeEvery(requirementAction.validateRequirement, validateRequirementAsync);
  },
  function* watchGetRequirementRecordChangeFieldsList() {
    yield takeEvery(
      requirementAction.getRequirementRecordChangeFieldsList,
      getRequirementRecordChangeFldsListAsync,
    );
  },
  function* watchGetRequirementStampingDetails() {
    yield takeEvery(
      requirementAction.getRequirementStampingDetails,
      getRequirementStampingDetailsAsync,
    );
  },
  function* watchGetRequirementList() {
    yield takeEvery(requirementAction.releaseRequirementUserLock, releaseRequirementUserLockAsync);
  },

  function* watchSaveRequirementAsync() {
    yield takeEvery(
      requirementAction.updateRequirementLockDetails,
      updateRequirementLockDetailsAsync,
    );
  },

  function* watchGetRequirementCompareDataAsync() {
    yield takeEvery(requirementAction.getRequirementCompareData, getRequirementCompareDataAsync);
  },
];
