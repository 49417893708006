import { shallowEqual, useSelector } from 'react-redux';
import {
  hiddenModule1FieldsClinicalTrialRegulation,
  hiddenModule1FieldsContactInfo,
  hiddenModule2FieldsContactInfo,
} from '../utils/requirement/constants';

const useModuleRequirementHiddenFields = () => {
  const { original } = useSelector(state => state.requirement, shallowEqual);
  const { moduleSelected } = original.recordUpdateAndSummary || {};
  const id = moduleSelected?.id || 1;

  if (!id) return {};
  let response = {};
  response.clinicalTrialRegulation = id === 1 ? hiddenModule1FieldsClinicalTrialRegulation : [];
  response.ethicsCommittee = id === 1 ? 'hide-all' : [];
  response.disclosureRegulationModel = id === 1 ? [] : 'hide-all';
  response.discloseRequirement = id === 1 ? [] : 'hide-all';
  response.clinicalTrialCharacteristicsModel = id === 1 ? [] : 'hide-all';
  response.protocolRegistrationRequirement = id === 1 ? [] : 'hide-all';
  response.plsProtocol = id === 1 ? [] : 'hide-all';
  response.resultDisclosureModel = id === 1 ? [] : 'hide-all';
  response.plsResults = id === 1 ? [] : 'hide-all';
  response.enforcement = id === 1 ? [] : 'hide-all';
  response.contactInfoModel =
    id === 1 ? hiddenModule1FieldsContactInfo : hiddenModule2FieldsContactInfo;

  return response;
};

export default useModuleRequirementHiddenFields;
