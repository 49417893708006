export const heading = 'Requirement feature';

export const requirementUrlStatus = [
  {
    id: 1,
    value: 'Active',
  },
  {
    id: 2,
    value: 'InActive',
  },
  {
    id: 3,
    value: 'Broken',
  },
];

export const hiddenModule1FieldsClinicalTrialRegulation = [
  'regulatoryAuthorityScope',
  'clinicalTrialAuthorizationDepartment',
  'responsibilityOfRegulatoryDepartment',
  'authorityForClinicalSubmission',
  'authorityContactDetails',
  'documentsForCTSubmission',
  'ctaSubmissionSourceUrl',
  'requirementSubmissionType',
  'otherDocSubmissionType',
  'clinicalTrialLanguages',
  'numOfRequiredCopies',
  'ctaScreeningForCompliance',
  'furtherDetailsOnCtaScreening',
  'requirementExemptionsToCTA',
  'furtherDetailsOnExemptionsCta',
  'authorityReviewTimeline',
  'otherTimelineAuthorityReview',
  'requirementCTAReviewOutcomes',
  'detailsOnAuthorityReviewFeedback',
  'automaticAccetance',
  'furtherDetailsOnAutomaticAcceptance',
  'orderOfSubmission',
  'otherOrderOfSubmission',
  'feesForCta',
  'clinicalTrialFeesSource',
  'clinicalTrialAdditionalLinking',
  'regulatoryDate',
  'keyDocumentClinicalTrialSubmission',
  'sourceDocs',
  'sourceDocsTable',
];

export const hiddenModule1FieldsContactInfo = [
  'contactInformationNameEthics',
  'contactDetailsEmailsEthics',
  'contactDetailsPhonesEthics',
  'websiteEthicsReq',
];

export const hiddenModule2FieldsContactInfo = [
  'contactInformationDisclosure',
  'contactEmailReDisclosure',
  'contactPhoneReDisclosure',
  'websiteDisclosureRegulation',
];
